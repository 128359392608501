import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"

import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"
import { colors } from "css/theme"
import { h2 } from "css/primitives"
import { BlogPost } from "../components"
import "src/queries/featured_media"

const wrapper = css`
  padding-bottom: 100px;
  overflow: hidden;
  margin-top: -90px;
  background-color: ${colors.darkBackground};
  @media (max-width: 479px) {
    padding-bottom: 75px;
  }
`

const wrapperTop = css`
  background-image: radial-gradient(
    circle at top,
    ${colors.background} 0%,
    black 100%
  );
  background-repeat: no-repeat;
  padding-top: 182px;
  padding-bottom: 300px;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 479px) {
    padding-top: 130px;
    padding-bottom: 270px;
  }
`

const heading = [
  h2,
  css`
    line-height: 1.14;
    font-size: 56px;
    color: white;
    text-align: center;
    margin-bottom: 1.5rem;
  `,
]

const headingText = css`
  color: white;
  opacity: 0.7;
  font-size: 20px;
  line-height: 1.6;
  text-align: center;
  max-width: 670px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0;
`

const blogListWrapper = css`
  padding-top: 3rem;
`

const postWidth = 336
const postGutterRight = 45

const blogList = css`
  list-style-type: none;
  margin-left: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  margin-top: -250px;
  @media (min-width: 992px) and (max-width: 1150px) {
    max-width: ${postWidth * 2 + postGutterRight}px;
  }
  @media (max-width: 991px) {
    max-width: none;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

function toProperCase(str) {
  if (!str) {
    return
  }
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

const IndexPage = ({ data }) => (
  <PageLayout options={{ headingBackground: false }}>
    <SEO
      title={`${data.category.name} Posts`}
      description={data.category.description}
    />
    <div css={wrapper}>
      <div css={wrapperTop}>
        <h1 css={heading}>Category: {toProperCase(data.category.name)}</h1>
        <p css={headingText}>{data.category.description}</p>
      </div>
      <div css={blogListWrapper}>
        <ul css={blogList}>
          {data.posts.edges.map((document, index) => {
            const post = document.node
            return <BlogPost post={post} key={index} />
          })}
        </ul>
      </div>
    </div>
  </PageLayout>
)

export default IndexPage

export const pageQuery = graphql`
  query CategoryTemplate($id: String!) {
    category: wordpressCategory(id: { eq: $id }) {
      name
      description
      slug
    }
    posts: allWordpressPost(
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
    ) {
      edges {
        node {
          id
          title
          content
          excerpt
          slug
          date
          status
          modified
          wordpress_id
          status
          ...FeaturedMedia
          author {
            id
            name
          }
        }
      }
    }
  }
`
